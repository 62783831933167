<template>
  <div>
    <v-alert
      outlined
      color="primary"
    >
      <div class="text-h5 mb-4">
        {{ 'message.greeting.title'| t({ name: $tokenStore.name}) }}
      </div>
      <html-content
        :value="$t('message.greeting.content', { site: siteName })"
      ></html-content>

      <v-btn
        :href="frontendDomain"
        target="_blank"
        class="mt-4 mb-2 white--text"
        color="green"
      >
        {{'go_to.frontend'| t}}
      </v-btn>
    </v-alert>

    <storage></storage>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  metaInfo() {
    return {
      title: this.$t('page.admin.entry'),
    }
  },
  computed: {
    siteName() {
      return this.$store.getters['base/siteName']
    },
    frontendDomain() {
      return this.$store.getters['base/frontendDomain']
    },
  },
  components: {
    storage: () => import('modules/base/components/systemInfo/storage.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
